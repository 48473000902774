.bannerDetail {
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    height: 300px;
    background-size: contain;
}

.bannerSection .slick-slider.slick-initialized .slick-prev {
    left: 25px;
    z-index: 10;
}

.bannerSection .slick-slider.slick-initialized .slick-next {
    right: 25px;
    z-index: 10;
}

.bannerSection>div>div>div>div {
    margin: 0 !important;
}


.categoryItemSlider>.slick-initialized {
    height: 500px !important;
}


.bannerDetail:before {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #0e0e0e;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #171717, #ffffff00);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #171717, #ffffff00);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    z-index: 1;
    content: "";
}

.bannerItem {
    position: relative;
}

.bannerItem:after {
    content: "";
    background: #2e2e2e7d;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
}

.bannerText {
    position: absolute;
    top: 50%;
    left: 9%;
    transform: translateY(-50%);
    z-index: 11;
    width: 60%;
    border-radius: 10px;
    text-align: left;
}

.bannerText h2 {
    font-size: 59px;
    line-height: 59px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 40px;

}

.bannerText h4 {
    color: #fff;
    text-decoration: underline;
    font-weight: 400;
    margin-bottom: 30px;
}

.bannerText p {
    color: #fff;
    font-size: 17px;
    margin: 25px 0;
    font-weight: 300;
}

.bannerText a {
    background: #cc5e28;
    border-radius: 0px;
    border-color: #cc5e28;
    color: #fff;
    border-radius: 0;
    padding: 6px 20px;
}

.bannerText a:hover {
    background: #cc5e28;
    background-color: #cc5e28;
    color: #fff;
}

.bannerContent .title h6 {
    color: #b1b1b1;
    text-transform: capitalize;
    display: none;
}

.bannerContent .title p {
    color: #626262;
}

.bannerContent .title h3 {
    font-size: 40px;
    text-transform: capitalize;
    color: #131011;
}

/* .percentOff {
    display: flex;
    align-items: center;
    column-gap: 20px;
} */
.bannerContent .title h1 {
    color: #fff;
    font-size: 80px;
    text-transform: uppercase;
    line-height: 60px;
    margin-bottom: 40px;

}

.bannerContent .priceSection {
    display: flex;
    align-items: center;
}

.bannerContent .priceSection .text {
    color: rgb(160, 160, 160);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
}

.bannerContent .priceSection .number {
    padding: 0 8px;
    color: #fb641b;
    font-weight: bold;
    font-size: 24px;
}

.shopNow a {
    display: inline-block;
    background-color: #cc5e28;
    color: #fff;
    padding: 10px 26px;
    text-transform: uppercase;
}
.bannerText ul {
    list-style: disc;
    padding-left: 25px;
}

.bannerText ul li {
    font-size: 18px;
    color: #fff;
}
@media only screen and (max-width: 991px) {
    .bannerText {
        top: 50%;
        left: 0;
        right: 0;
        width: 80%;
        padding: 20px 30px;
        margin: auto;
        text-align: center;
    }
}
@media (max-width: 767px) {



    .bannerText h2 {
        font-size: 35px;
    }

    .bannerText p {
        font-size: 16px;
    }
}
.brandsItem ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.brandsItem ul li {
    width: 23%;
}

.brandsItem ul li a {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 120px;
    padding: 20px;
    width: 160px;
}
.brandsItem ul li a img {
    object-fit: contain;
}
.serviceItemInfo {
    display: flex;
    justify-content: space-between;
}


.serviceItemIcon:hover img {
    transform: rotateY(180deg);
}

.serviceItemIcon img {
    transition: .4s ease-in-out;
}

.serviceItemIcon .rupee {
    color: #fb9678;
}

.serviceItemIcon .wallet {
    color: #e95454;
}

.serviceItemIcon .mobile {
    color: #fb641b;
}

.serviceItemIcon .offer {
    color: #b9bb41;
}

.serviceItemText h5 {
    text-transform: capitalize;
    font-size: 22px;
    margin: 0;
}

.serviceItemText p {
    margin: 0;
    font-size: 13px;
    line-height: 20px;
}

.serviceItemText a {
    color: #131011;
    font-weight: 400;
}



@media (max-width: 991px) {
    .serviceItem {
        border: 0;
    }

    .categoryBody {
        justify-content: space-between;
        column-gap: 10px;
    }

    .categoryBody .singleProducts {
        width: 45%;
    }

    .serviceItemInfo {
        flex-wrap: wrap;
        justify-content: center;
    }


    .categoryHeader a {
        padding: 2px 6px;
    }
}

@media (max-width: 767px) {
    .featuredContent h5 {
        text-transform: capitalize;
        font-size: 18px;
        height: 20px;
    }

    .featuredImg img {
        height: 100% !important;
    }


    .serviceItemText a {
        font-size: 10px;
    }
}

@media (max-width: 480px) {
    .serviceItem {
        width: 100%;
    }
}